import { forwardRef } from 'react';

import type IconProps from './iconTypes';

const Play = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  const { color = 'currentColor', ...otherProps } = props;

  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      ref={ref}
      {...otherProps}
    >
      <path
        d="M12 21a9 9 0 1 0 0-18 9 9 0 0 0 0 18z"
        stroke={color}
        strokeMiterlimit={10}
      />
      <path d="M16.5 12l-6.75 3.897V8.103L16.5 12z" fill={color} />
    </svg>
  );
});

Play.displayName = 'Play';

export default Play;
