import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

/**
 * Returns the parsed object from our query string
 * @returns {object}
 */
const useQueryStringObject = () => {
  const { search } = useLocation();
  const queryObject = useMemo(() => queryString.parse(search, { parseBooleans: true }), [search]);

  return queryObject;
};

export default useQueryStringObject;
